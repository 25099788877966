<template>
  <footer>
    <div id="notes">
      <h3>ملاحظات عشان لو واجهتك مشكلة</h3>
      <div class="faqs">
        <div class="faq">
          <div class="q">الموقع ده بيعمل ايه اصلا</div>
          <div class="a">
            <ul>
              <li>ببحث ف اكتر من موقع تورنت لحد ملاقي الفيلم</li>
              <li>بفلتر نتايج البحث و بجيب الاقل مساحة و التورنت الاسرع</li>
              <li>بحاول اجيب اكتر من جودة</li>
              <li>بحاول اجيب الترجمة عربي و انجليزي</li>
            </ul>
          </div>
        </div>
        <!-- <div class="faq">
          <div class="q">ليه مفيش مسلسلات</div>
          <div class="a">
            <p>
              كت عاوز اجمعلك كل السيزونات و الحلقات جمب بعض بس المشكلة لو انت
              عاوز بريكنج باد يعني 62 حلقة كده هعمل 62 ضعف العمليات اللي بتتعمل
              ف الفيلم الواحد ... ونا بحبك و بخاف ع النت بتاعك
            </p>
          </div>
        </div> -->
        <!-- <div class="faq">
          <div class="q">ليه مفيش مشاهدة اونلاين</div>
          <div class="a">
            <p>ممعيش اشتري سيرفر حتي الموقع ده حاطه ع استضافة مجانية :""(</p>
          </div>
        </div> -->
        <div class="faq">
          <div class="q">استخدم الموقع ازاي</div>
          <div class="a">
            <p>
              هتكتب اسم الفيلم (صح) و بعدين السنة (لو متعرفهاش فانا مطلعلك لينك
              يسيرش ف جوجل) و بعدين هتدوس زرار البحث #بديهيات
            </p>
          </div>
        </div>
        <div class="faq">
          <div class="q">ليه لازم ادخل السنة</div>
          <div class="a">
            <p>
              عشان لو فيه اكتر من نتيجة رجعت من البحث .. اقدر افلترهم علي
              النتيجة المظبوطة
            </p>
            <!-- <p>عشان افلترلك نتايج البحث و متتعبش نفسك و تختار</p> -->
          </div>
        </div>
        <div class="faq">
          <div class="q">ايه هو التورنت</div>
          <div class="a">
            <p>
              هو فايل حجمة صغير جدا بتحمل بيه الفيلم .. ميزته ان مهما النت فصل
              ونت بتحمل ف بترجع تحمل من مكان موقفت
            </p>
          </div>
        </div>
        <div class="faq">
          <div class="q">ازاي احمل التورنت</div>
          <div class="a">
            <p>
              لو انت وندوز او موبايل ف عندك
              <a href="https://www.utorrent.com/downloads/win" target="_blank"
                >uTorrent</a
              >
              و مجاني خش حمله
            </p>
          </div>
        </div>
        <div class="faq">
          <div class="q">ازاي اربط الفيلم بالترجمة</div>
          <div class="a">
            <ul>
              <li>
                لو انت موبايل فممكن تستخدم
                <a href="#" target="_blank">mxPlayer</a>
              </li>
              <li>
                لو وندوز معظم البرامج بتربط الفيلم بالترجمة لو الاتنين بنفس
                الاسم لو ده محصلش ف برشحلك <a href="#" target="_blank">vlc</a> و
                استخدامه سهل و لو معرفتش تربطهم ف هتلاقي شرح ف جوجل
              </li>
            </ul>
          </div>
        </div>
        <div class="faq">
          <div class="q">اعمل ايه لو الصوت مسابق الترجمة</div>
          <div class="a">
            <p>
              لو بتستخدم البرامج اللي قولت عليها فوق ف ال 2 بيسمحو انك تظبط
              الترجمة
            </p>
            <p>
              ال <a href="#" target="_blank">vlc</a> بتستخدم G, H عشان تقدم و
              تاخر الترجمة لحد متظبط معاك
            </p>
          </div>
        </div>
        <div class="faq">
          <div class="q">اعمل ايه لو الترجمة كانت حروفها غريبة</div>
          <div class="a">
            <!-- <p>اقفل الصوت عشان الموسيقي مزعجة :"""</p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/X7yX6F0fRso?start=45"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> -->
            <p>
              هتعملها ابلود هنا
              <a
                href="https://subtitletools.com/convert-text-files-to-utf8-online"
                target="_blank"
                >subtitletools</a
              >
              و بعدين تدوس ع الزرار الازرق و بعدين تحمل النسخة المتصلحة
            </p>
          </div>
        </div>
      </div>
    </div>
    <div id="footer">
      <div>
        Copyrights &copy;
        <a href="https://devmhmd.com" target="_blank">Mohamed Mahmoud</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "bottom"
};
</script>
